@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-pattern {
    background-image: url("./assets/wave.svg");
}

/*@font-face {*/
/*    font-family: 'Flamenco';*/
/*    src: url('Flamenco-Light.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Flamenco Regular';*/
/*    src: url('Flamenco-Regular.ttf') format('truetype');*/
/*}*/

/*body {*/
/*    margin: 0;*/
/*    !*font-family: 'Flamenco Regular', 'Flamenco', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*!*/
/*    !*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*!*/
/*    !*sans-serif;*!*/
/*    font-family:  'Roboto', -apple-system, Font, 'Segoe UI', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*h1 {*/
/*    font-size: 2em;*/
/*    font-weight: 800;*/
/*    color: #ffffff;*/
/*}*/

/*h2 {*/
/*    font-size: 1.5em;*/
/*    font-weight: 700;*/
/*}*/

/*h3 {*/
/*    font-size: 1.17em;*/
/*    font-weight: 600;*/
/*}*/

/*p {*/
/*    font-size: 1em;*/
/*}*/


/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

